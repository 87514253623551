.servicesContainer {
    padding: 40px 20px;
    max-width: 1000px;
    margin: auto;
    color: #333;
    line-height: 1.6;
    background: linear-gradient(to bottom right, #f5f7fa, #ffffff);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .heading {
    color: #2536ae;
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 20px;
    animation: fadeIn 1s ease-in-out;
  }
  
  .intro {
    font-size: 1em;
    margin-bottom: 40px !important;
    text-align: center;
    animation: fadeIn 1s ease-in-out;
  }
  
  .servicesList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  
  .service {
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: slideUp 0.5s ease-in-out;
  }
  
  .service:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .icon {
    color: #007bff;
    font-size: 1.8em;
    margin-bottom: 10px;
    transition: color 0.3s ease;
    float: right;
  }
  
  .service:hover .icon {
    color: #2536ae;
  }
  
  h3 {
    color: #2536ae;
    margin-bottom: 15px;
    font-size: 1.2em;

  }
  
  p {
    margin: 10px 0 0;
    font-size: 14px;
  }
  
  .whyChoose {
    margin-top: 40px;
    text-align: center;
  }
  
  .whyChoose h2 {
    color: #2536ae;
    font-size: 1.8em;
    margin-bottom: 20px;
    animation: fadeIn 1s ease-in-out;
  }
  
  .whyChoose p {
    margin: 10px 0;
  }
  
  .servicePtag{
    max-width: 350px;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @media (max-width: 768px) {
    .servicesList {
      grid-template-columns: 1fr;
    }
  
    .heading {
      font-size: 2em;
    }
  
    .intro {
      font-size: 1em;
    }
  
    .whyChoose h2 {
      font-size: 1.5em;
    }
  }
  