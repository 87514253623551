.contactContainer {
    padding: 30px;
    max-width: 1200px;
    margin: auto;
    color: #333;
    background: linear-gradient(to bottom right, #f5f7fa, #ffffff);
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.heading {
    color: #2536ae;
    font-size: 2.5em;
    text-align: center;
    animation: fadeIn 1s ease-in-out;
}

.enquiryFormSection {
    margin-bottom: 40px;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    text-align: center;
    animation: slideUp 0.5s ease-in-out;
    display: flex;
    justify-content: center;
}

.contactForm {
    width: 100%;
    max-width: 400px;
    text-align: left;
}

.formHeading {
    color: #444;
    font-size: 1.2em;
    margin-bottom: 20px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
}

.input,
.textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    font-family: "Poppins", sans-serif !important;
}

.input:focus,
.textarea:focus {
    border-color: #2536ae;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.textarea {
    height: 100px;
}

.uploadButton {
    display: block;
    width: 100%;
    background-color: red;
    color: #fff;
    padding: 12px 0;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    margin-bottom: 20px;
    border: none;
    text-align: center;
    font-family: "Poppins", sans-serif !important;
    transition: background-color 0.3s ease;
    position: relative;
    overflow: hidden;
}

.uploadButton:hover {
    background-color: darkred;
}

.fileInput {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.fileDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    text-wrap: wrap;
}

.removeButton {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
}

.removeButton:hover {
    color: darkred;
}

.sendButton {
    background-color: #007bff;
    color: #fff;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    font-family: "Poppins", sans-serif !important;
}

.sendButton:disabled{
    background-color: rgb(155, 155, 155);
    cursor: not-allowed;
}

/* .sendButton:hover {
    background-color: #ffeb3b;
    color: #2536ae;
} */

.contactDetailsSection {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    background: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    animation: fadeIn 1s ease-in-out;
}

.contactDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
}

.customatag {
    color: #444;
    text-decoration: none;
    font-weight: 500;
}

.customatag:hover {
    color: #004cff;
}

.detailItem {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 1em;
    color: #444;
    animation: slideUp 0.5s ease-in-out;
}

.icon {
    color: #007bff;
    font-size: 1.2em;
    margin-right: 10px;
}

.map {
    flex: 1;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    animation: fadeIn 1s ease-in-out;
}

.responsiveMap {
    width: 100%;
    height: 300px;
    border: 0;
    border-radius: 8px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .contactDetailsSection {
        flex-direction: column;
    }

    .enquiryFormSection {
        margin-bottom: 20px;
    }

    .heading {
        font-size: 2em;
    }

    .formHeading {
        font-size: 1.5em;
    }
}
