.floatingButton {
    position: fixed;
    bottom: 40px;
    right: 20px;
    background-color: #db0000;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 15px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, bottom 0.3s ease; 
    z-index: 998;
}

.floatingButton:hover {
    background-color: #c30000;
    transform: scale(1.04);
}

.floatingButtonWA {
    position: fixed;
    bottom: 40px;
    right: 222px;
    background-color: #43A047;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 11px;
    font-size: 30px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, bottom 0.3s ease; 
    z-index: 998;
}

.floatingButtonWA:hover {
    background-color: #2d6b30;
    color: white;
    transform: scale(1.05);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.aboveFooter {
    opacity: 0;
    display: block;
    transition: opacity .1s ease-in-out;
}

.aboveFooter.fadeIn {
    animation: fadeIn .1s ease-in forwards;
}

.aboveFooter.fadeOut {
    animation: fadeOut .1s ease-out forwards;
}
