.aboutContainer {
    color: #333;
    line-height: 1.6;
    padding: 50px 20px;
    animation: fadeIn 0.8s ease-in-out;
    opacity: 0;
    animation-fill-mode: forwards;
}

.headingSection {
    text-align: center;
    margin-bottom: 30px;
    animation: fadeIn 0.8s ease-in-out;
    opacity: 0;
    animation-fill-mode: forwards;
}

.heading {
    font-size: 2.5rem;
    color: #2536ae;
    letter-spacing: 2px;
    font-weight: 600;
}

.subHeading {
    font-size: 1.5rem;
    color: #555;
    font-weight: 500;
}

.aboutContent {
    margin: 0 auto;
    text-align: center;
    font-size: 1.1rem;
    color: #555;
    animation: fadeIn 0.8s ease-in-out;
    opacity: 0;
    animation-fill-mode: forwards;
}

.valuesSection {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 40px 0;
    padding: 20px 0;
    animation: fadeIn 0.8s ease-in-out;
    opacity: 0;
    animation-fill-mode: forwards;
}

.value {
    flex: 1 1 200px;
    background-color: #f0f4f8;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin: 10px;
    animation: fadeIn 0.8s ease-in-out;
    opacity: 0;
    animation-fill-mode: forwards;
}

.icon {
    font-size: 1.5rem;
    color: #007bff;
    margin-bottom: 10px;
}

.value h3 {
    font-size: 1.2rem;
    color: #2536ae;
    margin-bottom: 5px;
}

.value p {
    font-size: 0.9rem;
    color: #555;
}

.paymentSection {
    text-align: center;
    padding: 30px 10px;
    background-color: #f0f4f8;
    margin-top: 40px;
    border-radius: 10px;
    animation: fadeIn 0.8s ease-in-out;
    opacity: 0;
    animation-fill-mode: forwards;
}

.paymentSection h2 {
    font-size: 1.8rem;
    color: #2536ae;
    margin-bottom: 10px;
}

.paymentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

.paymentLogos {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 20px;
}

.paymentLogo {
    width: 60px;
    height: auto;
    transition: transform 0.3s;
}

.paymentLogo:hover {
    transform: scale(1.1);
}

.paymentDetails {
    padding: 10px;
    text-align: center;
}

.paymentUPI {
    width: 200px;
    height: auto;
    transition: transform 0.3s;
}

.paymentUPI:hover {
    transform: scale(1.2);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .valuesSection {
        flex-direction: column;
        align-items: center;
    }

    .value {
        max-width: none;
        margin: 20px 0;
    }

    .paymentDetails {
        flex-direction: column;
    }

    .paymentLogos {
        gap: 20px;
    }
}
