.header {
    width: 100%;
    background-color: #2536ae;
    color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
}
.customatag{
    color: white;
    text-decoration: none;
}
.customatag:hover{
    color:  #ffeb3b;;
}
.contactInfo {
    text-align: center;
    padding: 5px 0;
    background-color: #028c36;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
}

.marquee {
    display: inline-block;
    animation: scroll 20s linear infinite;
    white-space: nowrap;
}

.marquee:hover {
    animation-play-state: paused;
}

@keyframes scroll {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-100%);
    }
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.navbarBrand {
    display: flex;
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    transition: transform 0.3s ease;
}

.logo:hover {
    color: white;
}

.logoImage {
    height: 50px;
}

.hamburger {
    display: none;
    font-size: 2rem;
    cursor: pointer;
    color: white;
}

.closeIcon {
    font-size: 2rem;
    color: white;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.navLinks {
    display: flex;
    gap: 20px;
}

.navLink {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease, transform 0.3s ease;
}

.navLink:hover {
    color: #ffeb3b;
    transform: scale(1.1);
}

.navLinksMobileOpen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2536ae;
    z-index: 999;
    padding: 60px 20px 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    font-weight: 500;
}

.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 998;
    opacity: 1;
}

.overlay.overlayActive {
    display: block;
}

.activeLink {
    color: #ffeb3b;
    font-weight: 500;
    transform: scale(1.1);
}

.navLink:hover {
    color: #ffeb3b;
    transform: scale(1.05);
    transition: color 0.3s ease, transform 0.3s ease;
}

@media (max-width: 768px) {
    .logo {
        font-size: 1.1rem;
    }
    .hamburger {
        display: block;
    }

    .navLinks {
        display: none;
    }

    .navLinksMobileOpen {
        display: flex;
        transform: translateY(0);
    }
}

@media (min-width: 769px) {
    .navLinks {
        display: flex;
    }

    .navLinksMobileOpen {
        display: none;
    }

    .hamburger {
        display: none;
    }
}
