@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
.home {
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    color: #333;
    background-color: #f4f6f8;
}

.carousel {
    width: 100%;
    max-height: 400px;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1.5s ease-in-out;
}

.carousel img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.aboutSection {
    padding: 40px 20px;
    text-align: center;
    background-color: #ffffff;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: slideIn 1s ease-in-out;
}

.aboutTitle {
    font-size: 1.8rem;
    color: #2536ae;
    margin-bottom: 15px;
    font-weight: 600;
}

.aboutText {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    margin: 0 auto;
}

.featuredServices {
    padding: 40px 20px;
    text-align: center;
    background-color: #ffffff;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: slideIn 1s ease-in-out;
}

.sectionTitle {
    font-size: 1.6rem;
    color: #2536ae;
    margin-bottom: 20px;
    font-weight: 600;
}

.servicesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.serviceCard {
    flex: 1;
    max-width: 280px;
    min-width: 250px;
    background-color: #f0f4f8;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.serviceCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.serviceCard h3 {
    font-size: 1.2rem;
    color: #2536ae;
    margin-bottom: 10px;
}

.serviceCard p {
    font-size: 0.9rem;
    color: #666;
    line-height: 1.4;
}

.serviceIcon {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 10px;
}

.statisticsSection {
    display: flex;
    justify-content: space-around;
    padding: 40px 20px;
    background-color: #ffffff;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: slideIn 1s ease-in-out;
    text-align: center;
    overflow: hidden;
}

.statCard {
    flex: 1;
    margin: 0 10px;
    padding: 20px;
    background-color: #f0f4f8;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    min-width: 200px;
}

.statCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.statCard h3 {
    font-size: 2rem;
    color: #2536ae;
    margin: 10px 0;
}

.statCard p {
    font-size: 1rem;
    color: #555;
}

.statIcon {
    font-size: 3rem;
    color: #007bff;
    margin-bottom: 10px;
}

.testimonialsSection {
    padding: 40px 20px;
    text-align: center;
    background-color: #ffffff;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: slideIn 1s ease-in-out;
    overflow: hidden;
}

.testimonialsContainer {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    gap: 20px;
    overflow-x: auto;
    padding: 20px 0;
    scroll-behavior: smooth;
    white-space: nowrap;
}

.testimonialCard {
    flex: 0 0 auto;
    background-color: #f0f4f8;
    padding: 20px 0px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 270px;
    height: 400px;
    display: inline-block;
    transition: all 0.3s ease;
    text-align: left;
    overflow: hidden;
}

.testimonialCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.testimonialIcon {
    color: #f0b90b;
    display: flex;
    justify-content: center;
    gap: 5px;
    height: 30px; 
}

.testimonialCard p {
    background: rgb(0,0,0,.05);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    font-size: 0.9rem;
    color: #555;
    line-height: 1.4;
    margin-bottom: 10px;
    overflow: auto;
    text-wrap: wrap;
    padding: 0px 10px;
    text-align: center;
    font-style: italic;
}

.testimonialCard span {
    font-size: 0.8rem;
    color: #888;
    display: block;
    margin-top: 10px;
    height: 10%;
    text-align: right;
    display: flex;
    justify-content: center;
    align-items: center;
}

.testimonialsContainer::-webkit-scrollbar {
    height: 8px;
}

.testimonialsContainer::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

.testimonialsContainer::-webkit-scrollbar-track {
    background: #f0f4f8;
}

.testimonialCard p::-webkit-scrollbar {
    width: 4px;
    height: 8px;
}

.testimonialCard p::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

.testimonialCard p::-webkit-scrollbar-track {
    background: #f0f4f8;
}

.statisticsSection::-webkit-scrollbar {
    height: 8px;
}

.statisticsSection::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

.statisticsSection::-webkit-scrollbar-track {
    background: #f0f4f8;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .servicesContainer {
        flex-direction: column;
        align-items: center;
    }

    .serviceCard {
        max-width: 90%;
        margin-bottom: 20px;
    }

    .aboutTitle, .sectionTitle {
        font-size: 1.5rem;
    }

    .aboutText, .serviceCard p, .testimonialCard p {
        font-size: 0.9rem;
    }

    .serviceIcon {
        font-size: 1.8rem;
    }

    .statisticsSection {
        overflow-x: auto;
        gap: 20px; 
    }
    
    .statCard {
        width: 90%;
        margin: 10px 0;
    }

    .testimonialsContainer {
        flex-direction: row;
        justify-content: flex-start;
        overflow-x: auto;
    }

    .testimonialCard {
        width: 80%;
        margin: 0 10px;
    }
}

