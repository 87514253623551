.footer {
    background-color: #2536ae;
    color: white;
    padding: 20px;
    text-align: center;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
}

.footerContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    align-items: center;
}

.contactDetails {
    text-align: left;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.socialMedia {
    text-align: right;
    font-size: 1.5rem;
}

.socialIcon {
    margin: 0 10px;
    color: white;
    transition: transform 0.3s ease, color 0.3s ease;
}

.socialIcon:hover {
    color: #ffeb3b;
    transform: scale(1.2);
}

.customatag {
    color: white;
    text-decoration: none;
}

.customatag:hover {
    color: #ffeb3b;
}

.contactPcustom {
    display: flex;
    gap: 15px;
    align-items: center;
}

.copyright {
    text-align: center;
    padding: 10px;
    font-size: 0.8rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 20px;
}



@media (max-width: 768px) {
    .footerContainer {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .contactDetails, .socialMedia {
        text-align: center;
    }

    .contactPcustom {
        font-size: 0.8rem;
        text-align: justify;
    }

    .copyright {
        font-size: 0.7rem;
    }
    .contactIcon{
        font-size: 26px;
    }
}

@media (max-width: 380px) {
    .contactPcustom {
        font-size: 0.7rem;
    }
    .contactIcon{
        font-size: 26px;
    }
}

@media (max-width: 345px) {
    .contactPcustom {
        font-size: 0.6rem;
    }
    .contactIcon{
        font-size: 26px;
    }
}