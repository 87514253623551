* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif !important;
  background-color: #f0f2f5;
  color: #333; 
  line-height: 1.6;
  overflow: auto;
}

body::-webkit-scrollbar {
  display: none; 
}

html, body {
  overflow: -moz-scrollbars-none; 
  -ms-overflow-style: none;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1.5rem;
  color: #007bff; 
}

a {
  text-decoration: none;
  color: #007bff; 
  transition: color 0.3s ease;
}

a:hover {
  color: #0056b3;
}

p{
  margin:0 !important;
}

button {
  cursor: pointer;
  font-family: inherit;
  transition: background-color 0.3s ease, color 0.3s ease;
}

input, textarea {
  font-family: inherit;
  font-size: 1rem;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}


.mt-1 { margin-top: 1rem; }
.mt-2 { margin-top: 2rem; }
.mt-3 { margin-top: 3rem; }

.mb-1 { margin-bottom: 1rem; }
.mb-2 { margin-bottom: 2rem; }
.mb-3 { margin-bottom: 3rem; }

.pt-1 { padding-top: 1rem; }
.pt-2 { padding-top: 2rem; }
.pt-3 { padding-top: 3rem; }

.pb-1 { padding-bottom: 1rem; }
.pb-2 { padding-bottom: 2rem; }
.pb-3 { padding-bottom: 3rem; }

.text-center {
  text-align: center;
}

.hide-scrollbar {
  overflow: auto; 
  scrollbar-width: none;
  -ms-overflow-style: none; 
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
      padding: 10px;
  }

  .navbarBrand {
      font-size: 1.2rem;
  }

  .navLinks {
      flex-direction: column;
      align-items: center;
  }

  .navLink {
      margin: 10px 0;
  }
}
